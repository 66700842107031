/* ******************* */
/* || BODY */
body {
	margin: 0;
	border: 8px solid rgb(85, 81, 255);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body.flash {
	animation-name: flash;
	animation-duration: 1s;
}
@keyframes flash {
	from {
		background: #699bf7;
	}
	to {
		background: #fff;
	}
}
/* ******************* */
/* || CONTAINER */
.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 15px;
}
/* ******************* */
/* || SITE LOGO */
.site-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border: 5px solid #000;
	margin-right: 8px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}
.site-logo:hover {
	border-radius: 50%;
	transform: rotate(360deg);
}
.site-logo .logo-text {
	color: rgb(85, 81, 255);
	font-family: "Recursive", monospace;
	font-size: 1.25em;
	font-weight: 800;
	line-height: 1;
}
/* ******************* */
/* || BUTTON */
.btn {
	display: inline-block;
	padding: 14px 24px 16px;
	background: rgb(0, 0, 0);
	border: 3px solid transparent;
	border-radius: 10px;
	color: rgb(255, 255, 255);
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	transition: transform 200ms ease 0s, background 200ms ease 0s;
	cursor: pointer;
}
.btn.small {
	padding: 8px 15px;
	border-radius: 8px;
	font-size: 1em;
	text-decoration: none;
}
.btn:hover,
.btn.small:hover {
	text-decoration: underline;
	text-decoration-color: rgb(4, 216, 157);
	text-decoration-thickness: 3px;
	transform: translateY(-2px);
}
.btn:active,
.btn.small:active {
	transform: translateY(1px);
}
/* ******************* */
/* || FOCUS STYLES */
.btn:focus-visible,
span.close:focus-visible,
a.credit:focus-visible,
.trick-list #trick-selector:focus-visible,
.trick-container .pov-controls select:focus-visible {
	border: 3px solid rgb(4, 216, 157);
	border-radius: 4px;
	outline: 0px;
}
/* ******************* */
/* || LOADER */
.loader,
.loader:after {
	border-radius: 50%;
	width: 8em;
	height: 8em;
}
.loader {
	margin: 20px auto;
	font-size: 8px;
	position: absolute;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(255, 255, 255, 0.2);
	border-right: 1.1em solid rgba(255, 255, 255, 0.2);
	border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
	border-left: 1.1em solid rgb(85, 81, 255);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
@keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
/* ******************* */
/* || HEADER */
header {
	padding: 15px 0;
}
header div.container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
header h1 {
	display: inline-block;
	width: 80px;
	margin: 0;
	font-family: "Recursive", monospace;
	font-size: 1em;
	font-weight: 800;
	line-height: 1.2;
	transform: translateY(-1px);
}
/* ******************* */
/* || TRICK LIST */
.trick-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 16px;
}
.trick-list .error {
	margin-left: 10px;
	color: rgb(255, 65, 151);
	font-weight: 600;
}
.trick-list form {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
/* always show down arrow in datalist */
.trick-list form input::-webkit-calendar-picker-indicator {
	opacity: 100;
}
.trick-list label {
	margin-bottom: 6px;
	font-family: "Recursive", monospace;
}
.trick-list #trick-selector {
	display: block;
	height: 40px;
	padding-left: 12px;
	border: 3px solid rgb(40, 40, 40);
	border-radius: 4px;
	margin-top: 5px;
	margin-bottom: 20px;
	color: rgb(40, 40, 40);
	font-size: 18px;
}
/* ******************* */
/* || TRICK CONTAINER */
.trick-container {
	margin-bottom: 24px;
}
.trick-container h2 {
	margin: 0 0 8px 0;
	font-family: "Recursive", monospace;
	font-weight: 800;
	line-height: 1;
}
.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
}
.trick-container img {
	box-sizing: border-box;
	width: 100%;
	max-width: 960px;
	height: 0;
	border: 1px solid rgb(40, 40, 40);
	border-radius: 4px;
	opacity: 0;
}
.trick-container img.show {
	height: auto;
	opacity: 1;
}
.trick-container .pov-controls {
	display: flex;
	flex-direction: column;
}
.trick-container .pov-controls label {
	font-family: "Recursive", monospace;
}
.trick-container .pov-controls select {
	display: inline-block;
	height: 40px;
	padding-left: 12px;
	border: 3px solid rgb(40, 40, 40);
	border-radius: 4px;
	margin-top: 5px;
	margin-bottom: 16px;
	color: rgb(40, 40, 40);
	font-size: 18px;
	cursor: pointer;
}
.trick-container h3 {
	margin: 0 0 6px 0;
	font-family: "Recursive", monospace;
	font-weight: 600;
}
.trick-container p {
	margin-top: 0;
}
/* ******************* */
/* || FOOTER */
footer {
	padding: 10px 0;
	margin-bottom: 30px;
}
footer .menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
footer .site-logo {
	width: 40px;
	height: 40px;
	border: 4px solid #000;
}
footer .site-logo .logo-text {
	font-size: 1em;
}
footer .links span {
	margin-right: 20px;
}
/* credit to bog */
footer .credit {
	margin-top: 30px;
	color: #777;
	font-size: 13px;
}
a.credit {
	color: rgb(255, 65, 151);
	text-decoration: none;
}
a.credit:hover {
	text-decoration: underline;
}
/* ******************* */
/* || MODAL */
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: scroll;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: all 0.3s ease-in-out;
	pointer-events: none;
}
.modal.enter-done {
	opacity: 1;
	pointer-events: visible;
}
.modal.exit {
	opacity: 0;
}
/* modal window */
.modal-content {
	width: 500px;
	margin: 0 8px;
	padding-bottom: 16px;
	background: #fff;
	border: 8px solid rgb(255, 199, 0);
	transform: translateY(-200px);
	transition: all 0.3s ease-in-out;
}
.modal.enter-done .modal-content {
	transform: translateY(0);
}
.modal.exit .modal-content {
	transform: translateY(-200px);
}
/* modal close button */
.modal .close:focus-visible {
	outline: 3px solid rgb(4, 216, 157);
}
.modal .close svg {
	float: right;
	width: 16px;
	margin: 16px;
	transition: transform 200ms ease 0s, background 200ms ease 0s;
	cursor: pointer;
}
.modal .close svg:hover {
	transform: translateY(-2px);
}
.modal .close svg:active {
	transform: translateY(1px);
}
/* modal header */
.modal-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px dashed rgb(85, 81, 255);
}
.modal-header .site-logo {
	width: 40px;
	height: 40px;
	margin-right: 15px;
	border: 4px solid #000;
}
.modal-header .site-logo .logo-text {
	font-size: 1em;
}
.modal-header h1 {
	font-family: "Recursive", monospace;
	font-weight: 600;
	line-height: 1;
}
/* ******************* */
/* || ABOUT: content for modal */
.about-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.about-content h2 {
	font-size: 1em;
}
/* media queries */
@media only screen and (min-width: 400px) {
	.about-content h2 {
		font-size: 1.3em;
	}
}
@media only screen and (min-width: 500px) {
	.about-content h2 {
		font-size: 1.6em;
	}
}
@media only screen and (min-width: 600px) {
	.container {
		max-width: 600px;
	}
	header svg {
		width: 50px;
		margin-right: 20px;
	}
	.trick-container .pov-controls {
		margin-bottom: 16px;
	}
	.trick-list {
		padding-bottom: 32px;
	}
	.about-content .btn {
		margin: 20px 0;
	}
}
